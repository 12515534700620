import { cn } from '~/utils/cn';

export function Logo(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 122 32" {...props} className={cn('h-8 text-foreground', props.className)}>
      <g>
        <path
          d="M0.160156 21.2172C0.160156 24.2628 1.13737 26.801 3.09178 28.8517C5.0462 30.9024 7.49919 31.9177 10.4109 31.9177C11.7869 31.9177 13.0633 31.6944 14.2399 31.2273C15.4166 30.7806 16.5534 30.0497 17.6502 29.0751C18.089 28.6893 18.4679 28.2425 18.767 27.735C19.0462 27.2476 19.2058 26.8213 19.2058 26.4557C19.2058 26.1714 19.106 25.9278 18.9465 25.7654C18.7869 25.603 18.5477 25.5014 18.2684 25.5014C17.8296 25.5014 17.2514 25.806 16.5333 26.3949C16.1744 26.6791 15.9151 26.8821 15.7356 27.0039C15.1972 27.3695 14.6188 27.6537 14.0006 27.8365C13.3824 28.0396 12.7243 28.1207 12.0462 28.1207C10.0519 28.1207 8.41657 27.3289 7.14022 25.7451C5.86386 24.1613 5.22569 22.1105 5.22569 19.5928C5.22569 17.4202 5.66443 15.7349 6.56187 14.5166C7.45931 13.2983 8.71571 12.6893 10.3311 12.6893C11.1687 12.6893 11.8667 12.8922 12.4052 13.2983C12.9237 13.7044 13.4223 14.3948 13.861 15.4101C14.3397 16.5674 14.7784 17.3187 15.2172 17.6435C15.636 17.9888 16.1943 18.1512 16.9122 18.1512C17.5904 18.1512 18.1688 17.9481 18.6074 17.542C19.0462 17.1359 19.2855 16.5877 19.2855 15.938C19.2855 14.3136 18.4679 12.9126 16.8724 11.6943C15.277 10.476 13.3425 9.86694 11.069 9.86694C7.95788 9.86694 5.34535 10.9431 3.27126 13.0954C1.19719 15.2476 0.160156 17.9684 0.160156 21.2172Z"
          fill="currentColor"
        />
        <path
          d="M25.7263 19.1055C25.7263 17.2171 26.1451 15.6944 26.9827 14.5573C27.8204 13.4202 28.9172 12.8314 30.2933 12.8314C31.9286 12.8314 33.3446 13.7857 34.5213 15.6537C35.6979 17.5217 36.2961 19.8364 36.2961 22.5979C36.2961 24.5066 35.8574 26.0496 35.0199 27.2071C34.1821 28.3847 33.0853 28.9532 31.7293 28.9532C30.054 28.9532 28.6181 28.0192 27.4614 26.1309C26.3047 24.2628 25.7263 21.9075 25.7263 19.1055ZM30.8318 31.9177C33.8631 31.9177 36.3959 30.8212 38.45 28.6283C40.4842 26.4354 41.5213 23.7147 41.5213 20.4253C41.5213 17.339 40.564 14.8212 38.6495 12.8314C36.7349 10.8618 34.3018 9.86694 31.3103 9.86694C28.1993 9.86694 25.6066 10.9228 23.5327 13.0344C21.4586 15.1461 20.4414 17.806 20.4414 20.9735C20.4414 24.141 21.4187 26.74 23.393 28.8111C25.3474 30.8822 27.8403 31.9177 30.8318 31.9177Z"
          fill="currentColor"
        />
        <path
          d="M45.1248 15.1461C45.1248 16.2222 45.1248 17.5623 45.1446 19.1867C45.1446 20.811 45.1647 21.9075 45.1647 22.476C45.1647 24.4049 45.1047 25.8059 45.0051 26.6588C44.9053 27.5116 44.7457 28.0597 44.5065 28.2831C44.3668 28.4253 44.0079 28.6283 43.4495 28.8719C42.8711 29.136 42.592 29.4811 42.592 29.9075C42.592 30.5369 42.911 30.9633 43.5891 31.1866C44.2672 31.4304 45.6631 31.5319 47.7771 31.5319C49.572 31.5319 50.8285 31.4304 51.5264 31.2069C52.2045 30.9836 52.5634 30.5979 52.5634 30.0496C52.5634 29.5217 52.2244 29.1156 51.5863 28.8517C50.948 28.6079 50.5492 28.4049 50.4295 28.2425C50.1902 27.9785 50.0306 27.3288 49.951 26.2932C49.8712 25.2577 49.8313 22.8618 49.8313 19.1055C49.8313 13.9886 49.9111 8.91257 50.0705 3.87701C50.0905 2.98354 50.1104 2.45563 50.1104 2.25265C50.1104 1.60292 50.0108 1.13586 49.8512 0.87191C49.6717 0.607957 49.3726 0.46582 48.9737 0.46582C48.4154 0.46582 47.6973 0.567314 46.7999 0.77042C45.9025 0.973405 44.9851 1.23736 44.0278 1.56228C43.0307 1.9074 42.4124 2.19168 42.1333 2.43531C41.854 2.67906 41.7344 2.96334 41.7344 3.30846C41.7344 3.81604 42.1732 4.18161 43.0507 4.42524C43.5891 4.5877 44.0079 4.72984 44.3269 4.89218C44.6062 5.05464 44.8255 5.66384 44.9452 6.679C45.0649 7.71462 45.1248 9.9887 45.1248 13.5217V15.1461Z"
          fill="currentColor"
        />
        <path
          d="M70.7069 20.608C71.7439 20.608 72.3822 20.4862 72.6414 20.2019C72.8808 19.9177 73.0203 19.3492 73.0203 18.476C73.0203 15.8365 72.2226 13.7451 70.667 12.2019C69.1115 10.6588 66.9975 9.86694 64.3251 9.86694C61.1542 9.86694 58.5617 10.9431 56.5275 13.075C54.4932 15.207 53.4961 17.9278 53.4961 21.2172C53.4961 24.2628 54.4732 26.801 56.4477 28.8517C58.422 30.9024 60.895 31.9177 63.8466 31.9177C65.1627 31.9177 66.3792 31.7146 67.4961 31.268C68.6129 30.8212 69.7696 30.0902 70.9861 29.0751C71.5645 28.608 72.0232 28.1207 72.3822 27.6335C72.7212 27.1461 72.9006 26.7197 72.9006 26.3745C72.9006 26.0903 72.801 25.8466 72.6015 25.6435C72.4021 25.4406 72.1428 25.3391 71.8437 25.3391C71.4448 25.3391 70.9063 25.6435 70.2283 26.2121C69.9092 26.476 69.6499 26.6791 69.4705 26.801C68.7923 27.2882 68.1143 27.6335 67.4163 27.8568C66.6984 28.1004 65.9804 28.2019 65.2226 28.2019C63.4477 28.2019 61.9519 27.5725 60.7154 26.3136C59.479 25.0548 58.8608 23.5319 58.8608 21.7654C58.8608 21.2375 58.9206 20.9126 59.0801 20.7908C59.2197 20.6689 59.6784 20.608 60.4562 20.608H70.7069ZM60.3166 18.1918C59.798 18.1918 59.459 18.1512 59.2995 18.0293C59.1399 17.9278 59.0602 17.7248 59.0602 17.3999C59.0602 16.0395 59.479 14.9025 60.3565 13.9684C61.214 13.0344 62.3109 12.5674 63.6271 12.5674C64.9833 12.5674 66.12 13.0344 67.0175 13.9684C67.9149 14.9025 68.3735 16.0395 68.3735 17.3999C68.3735 17.7248 68.2739 17.9278 68.1143 18.0293C67.9349 18.1512 67.5758 18.1918 67.0574 18.1918H60.3166Z"
          fill="currentColor"
        />
        <path
          d="M86.0592 10.4558H81.4125V6.49652C81.4125 6.29342 81.3327 6.11063 81.1732 5.98882C81.0136 5.867 80.7943 5.80615 80.515 5.80615C79.9765 5.80615 78.9994 6.45588 77.5634 7.735C77.2045 8.05992 76.9452 8.28323 76.7657 8.44569C76.5464 8.64879 76.2672 8.89242 75.9083 9.19702C74.4524 10.4558 73.7344 11.329 73.7344 11.7757V12.2833C73.7344 12.5472 73.7743 12.73 73.8939 12.8315C74.0136 12.9533 74.2131 12.994 74.5122 12.994H76.4267V24.3442C76.4267 26.4558 76.5264 27.9179 76.7258 28.6894C76.9253 29.4609 77.2643 30.0903 77.7629 30.5371C78.2416 31.0041 78.8398 31.3493 79.5179 31.5727C80.196 31.8163 80.9737 31.9178 81.8512 31.9178C83.327 31.9178 84.6632 31.5727 85.8398 30.8417C87.0164 30.1107 87.6148 29.3594 87.6148 28.5879C87.6148 28.3036 87.515 28.0599 87.3157 27.8772C87.1162 27.6944 86.857 27.5929 86.5577 27.5929C86.2786 27.5929 85.7999 27.7148 85.1219 27.9381C84.4439 28.1818 83.8854 28.2833 83.4467 28.2833C82.629 28.2833 82.0706 28.0599 81.7714 27.5929C81.4723 27.1462 81.3327 26.0092 81.3327 24.1818V13.664C81.3327 13.4609 81.3726 13.2985 81.4524 13.1766C81.5322 13.0548 81.6318 12.994 81.7714 12.994H86.0592C86.3783 12.994 86.5976 12.9127 86.7373 12.7502C86.8769 12.5879 86.9566 12.3239 86.9566 11.9584V11.4508C86.9566 11.065 86.8769 10.8011 86.7572 10.6589C86.6375 10.5371 86.3983 10.4558 86.0592 10.4558Z"
          fill="currentColor"
        />
        <path
          d="M99.7936 10.4558H95.1469V6.49652C95.1469 6.29342 95.0671 6.11063 94.9075 5.98882C94.748 5.867 94.5286 5.80615 94.2494 5.80615C93.7109 5.80615 92.7338 6.45588 91.2978 7.735C90.9389 8.05992 90.6797 8.28323 90.5001 8.44569C90.2808 8.64879 90.0015 8.89242 89.6426 9.19702C88.1868 10.4558 87.4688 11.329 87.4688 11.7757V12.2833C87.4688 12.5472 87.5086 12.73 87.6283 12.8315C87.748 12.9533 87.9474 12.994 88.2466 12.994H90.1611V24.3442C90.1611 26.4558 90.2609 27.9179 90.4602 28.6894C90.6597 29.4609 90.9987 30.0903 91.4973 30.5371C91.976 31.0041 92.5742 31.3493 93.2522 31.5727C93.9304 31.8163 94.7081 31.9178 95.5856 31.9178C97.0614 31.9178 98.3976 31.5727 99.5742 30.8417C100.751 30.1107 101.349 29.3594 101.349 28.5879C101.349 28.3036 101.249 28.0599 101.05 27.8772C100.851 27.6944 100.591 27.5929 100.292 27.5929C100.013 27.5929 99.5343 27.7148 98.8563 27.9381C98.1782 28.1818 97.6198 28.2833 97.1811 28.2833C96.3634 28.2833 95.805 28.0599 95.5059 27.5929C95.2067 27.1462 95.0671 26.0092 95.0671 24.1818V13.664C95.0671 13.4609 95.107 13.2985 95.1868 13.1766C95.2665 13.0548 95.3662 12.994 95.5059 12.994H99.7936C100.113 12.994 100.332 12.9127 100.472 12.7502C100.611 12.5879 100.691 12.3239 100.691 11.9584V11.4508C100.691 11.065 100.611 10.8011 100.492 10.6589C100.372 10.5371 100.133 10.4558 99.7936 10.4558Z"
          fill="currentColor"
        />
        <path
          d="M119.098 20.608C120.135 20.608 120.773 20.4862 121.032 20.2019C121.271 19.9177 121.411 19.3492 121.411 18.476C121.411 15.8365 120.613 13.7451 119.058 12.2019C117.502 10.6588 115.388 9.86694 112.716 9.86694C109.545 9.86694 106.952 10.9431 104.918 13.075C102.884 15.207 101.887 17.9278 101.887 21.2172C101.887 24.2628 102.864 26.801 104.838 28.8517C106.813 30.9024 109.286 31.9177 112.237 31.9177C113.553 31.9177 114.77 31.7146 115.887 31.268C117.004 30.8212 118.16 30.0902 119.377 29.0751C119.955 28.608 120.414 28.1207 120.773 27.6335C121.112 27.1461 121.291 26.7197 121.291 26.3745C121.291 26.0903 121.192 25.8466 120.992 25.6435C120.793 25.4406 120.533 25.3391 120.234 25.3391C119.835 25.3391 119.297 25.6435 118.619 26.2121C118.3 26.476 118.041 26.6791 117.861 26.801C117.183 27.2882 116.505 27.6335 115.807 27.8568C115.089 28.1004 114.371 28.2019 113.613 28.2019C111.838 28.2019 110.343 27.5725 109.106 26.3136C107.87 25.0548 107.251 23.5319 107.251 21.7654C107.251 21.2375 107.311 20.9126 107.471 20.7908C107.61 20.6689 108.069 20.608 108.847 20.608H119.098ZM108.707 18.1918C108.189 18.1918 107.85 18.1512 107.69 18.0293C107.531 17.9278 107.451 17.7248 107.451 17.3999C107.451 16.0395 107.87 14.9025 108.747 13.9684C109.605 13.0344 110.702 12.5674 112.018 12.5674C113.374 12.5674 114.511 13.0344 115.408 13.9684C116.305 14.9025 116.764 16.0395 116.764 17.3999C116.764 17.7248 116.665 17.9278 116.505 18.0293C116.325 18.1512 115.966 18.1918 115.448 18.1918H108.707Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
